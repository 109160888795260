/* Add this CSS for the animation */
@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Apply the animation to the content */
.main {
  animation: slideInFromRight 0.5s ease-in-out;
}


.App {
  text-align: center;
  font-family: sans-serif;
  margin: 2rem;
}

.container{
  display: grid;
  margin: 2rem;
  padding: 2rem 10rem;
  background-color: transparent;
  border: 2px solid white;
  border-radius: 2rem;
  box-shadow: 0 0 5px 0 ; 
  background: inherit; 
  backdrop-filter: blur(5px); 
}

.logo{
  display: flex;
  justify-content: center;
}

.heading{
  margin-top: 2rem;
}

.white{
  color: whitesmoke;
}

button{
  display: inline-flex !important;
  align-items: center;
}

iframe{
width: 100%;
}

.radio{
  display: inline-flex;
  color: white;
  align-items: center;
}

.radio > label{
  font-size: 2rem;
}

.inline-radio-1{
  color: greenyellow !important;
}

.inline-radio-2{
  color: red !important;
}

.transaction{
  display: contents;
  align-items: center;

}